// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arm-textarea {
	height: 31.25rem;
	width: 100%;
	border: 2px solid #000;
	font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/AddRuleModal/AddRuleModal.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,WAAW;CACX,sBAAsB;CACtB,eAAe;AAChB","sourcesContent":[".arm-textarea {\n\theight: 31.25rem;\n\twidth: 100%;\n\tborder: 2px solid #000;\n\tfont-size: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
