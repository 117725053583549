// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.router-box-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.top-heading {
	font-size: 1.2rem;
	font-weight: bold;
}

.router-section {
	width: 6.25rem;
	border: 1px solid #FF9700;
	border-radius: 0.625rem;
	font-size: 1.0rem;
	padding-left: 1rem;
	padding-right: 1rem;
}

.identified-device-section {
	margin-top: 2rem;
}

.identified-device {
	width: 6.25rem;
	border: 1px solid #FF9700;
	border-radius: 0.625rem;
	font-size: 1rem;
	padding-left: 1rem;
	padding-right: 1rem;
	text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/RouterBox/RouterBox.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;AACpB;;AAEA;CACC,iBAAiB;CACjB,iBAAiB;AAClB;;AAEA;CACC,cAAc;CACd,yBAAyB;CACzB,uBAAuB;CACvB,iBAAiB;CACjB,kBAAkB;CAClB,mBAAmB;AACpB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,cAAc;CACd,yBAAyB;CACzB,uBAAuB;CACvB,eAAe;CACf,kBAAkB;CAClB,mBAAmB;CACnB,gBAAgB;AACjB","sourcesContent":[".router-box-container {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n}\n\n.top-heading {\n\tfont-size: 1.2rem;\n\tfont-weight: bold;\n}\n\n.router-section {\n\twidth: 6.25rem;\n\tborder: 1px solid #FF9700;\n\tborder-radius: 0.625rem;\n\tfont-size: 1.0rem;\n\tpadding-left: 1rem;\n\tpadding-right: 1rem;\n}\n\n.identified-device-section {\n\tmargin-top: 2rem;\n}\n\n.identified-device {\n\twidth: 6.25rem;\n\tborder: 1px solid #FF9700;\n\tborder-radius: 0.625rem;\n\tfont-size: 1rem;\n\tpadding-left: 1rem;\n\tpadding-right: 1rem;\n\ttext-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
