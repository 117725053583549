import React from 'react';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';
import { useUserLogin } from '../../httpRequestHooks/useLogin';


const LoginPage = () => {

	const [username, setUsername] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [deviceId, setDeviceId] = React.useState('');

	const navigate = useNavigate();

	const handleChangeUsername = (e) => {
		setUsername(e.target.value);
	};
	const handleChangePassword = (e) => {
		setPassword(e.target.value);
	};
	const handleChangeDeviceId = (e) => {
		setDeviceId(e.target.value);
	};

	const saveSessionInfo = () => {
		sessionStorage.setItem('username', username);
		sessionStorage.setItem('password', btoa(password));
		sessionStorage.setItem('deviceId', deviceId);
	};

	const onLoginSuccess = () => {
		saveSessionInfo();
		navigate('/workflow');
	};

	const onLoginError = () => {
		console.log(errorMessage);
	};

	const {
		mutate: userLogin,
		isPending: loading,
		isError: isError,
		error: errorMessage,
	} = useUserLogin(onLoginSuccess, onLoginError);


	const onSubmit = () => {
		const requestObject = {
			username,
			password: btoa(password),
			deviceId
		};
		userLogin({ ...requestObject });
	};


	return (
		<div className='login-page-screen'>
			<div className='login-page-container'>
				<div className='login-container-header'>
					Welcome to AirDi Cloud Solution UI Emulator!
				</div>
				<p className='login-container-text'>
					The UI emulator visualizes device data flow from Airties CPE through AirDI Cloud to provide real-time data to Careview And Vision App
				</p>

				<div className='login-container-form'>

					<div className='login-form-group'>
						<label className='login-form-label'>Username</label>
						<input type='text' value={username} onChange={(e) => handleChangeUsername(e)} className='login-form-input' />
					</div>

					<div className='login-form-group'>
						<label className='login-form-label'>Password</label>
						<input type='password' value={password} onChange={(e) => handleChangePassword(e)} className='login-form-input ' />
					</div>

					<div className='login-form-group'>
						<label className='login-form-label'>Device ID for Home</label>
						<input type='text' value={deviceId} onChange={(e) => handleChangeDeviceId(e)} className='login-form-input' />
					</div>
					{
						isError && <div className='login-error-container'>
							<p className='login-error-message'>{errorMessage.message}</p>
						</div>
					}

					<button className='login-form-button' onClick={() => onSubmit()}>{loading ? <div className='loader'></div> : 'Submit'}</button>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;