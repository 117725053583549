import React, { useMemo } from 'react';
import styles from './index.module.css';
import { toast } from 'react-toastify';
import { AddRuleModal } from '../AddRuleModal/index.jsx';
import classNames from 'classnames';
import { IoIosAddCircle } from 'react-icons/io';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { useFetchAndUpdateRules } from '../../httpRequestHooks/useFetchAndUpdateRules.js';
import { useApplyRules } from '../../httpRequestHooks/useApplyRules.js';

const AddRuleToggle = React.forwardRef(({ onClick }, ref) => (
	<Button ref={ref} className={classNames(styles.button, styles['update-rule-button'])} onClick={onClick}>
		<IoIosAddCircle className={styles['button-icon']} />
		Update Rules
	</Button>
));

AddRuleToggle.displayName = 'AddRuleToggle';
AddRuleToggle.propTypes = {
	onClick: PropTypes.func,
	size: PropTypes.string,
};

const AddNewRule = () => {

	const onUpdateRuleSuccess = (data) => {
		if (typeof (data) == 'string' && data === 'Success') {
			toast.success(data);
			applyRules({
				username: sessionStorage.getItem('username'),
				password: sessionStorage.getItem('password')
			});
		} else {
			toast.error(data);
		}
	};
	const onUpdateRuleFailed = (error) => {
		toast.error(error.message);
	};

	const onApplyRulesSuccess = (data) => {
		toast.success(data);
	};

	const onApplyRulesFailed = (error) => {
		toast.error(error.message);
	};


	const {
		updateRuleMutation,
		ruleConfigFiles,
		rule,
		ruleContent,
	} = useFetchAndUpdateRules(
		onUpdateRuleSuccess,
		onUpdateRuleFailed,
	);

	const showModal = useMemo(() => !!rule.selectedRule, [rule.selectedRule]);


	const { mutate: applyRules, isPending: loading } = useApplyRules(onApplyRulesSuccess, onApplyRulesFailed);

	return (
		<>
			<div className={styles['buttons-container']}>
				<Dropdown>
					<Dropdown.Toggle variant="primary" as={AddRuleToggle}>
						{loading ? <div className='loader'></div> :
							<AddRuleToggle />
						}
					</Dropdown.Toggle>

					<Dropdown.Menu>
						{
							(ruleConfigFiles ?? []).map(
								ruleConfig =>
									<Dropdown.Item
										key={ruleConfig}
										onClick={() => rule.setSelectedRule(ruleConfig)}>
										{ruleConfig}
									</Dropdown.Item>
							)
						}
					</Dropdown.Menu>
				</Dropdown>
			</div>

			<AddRuleModal
				isLoading={ruleContent.isLoading}
				show={showModal}
				rule={ruleContent.selectedRuleContent}
				title={`Update rule configurations for file ${rule.selectedRule}`}
				handleClose={() => rule.setSelectedRule(null)}
				onSubmit={() => updateRuleMutation.mutateAsync(rule.selectedRule)}
				onRuleChange={ruleContent.setSelectedRuleContent}
			/>
		</>
	);
};

export default AddNewRule;