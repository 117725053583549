// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.ui-container {
	width: 100%;
	height: 100%;
}

.logout-container {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000;
}

.logout-container Button {
    border: none;
    text-align: center;
    display: block;
    width: 100%;
}

.logout-container div {
	color: gray;
    text-align: center;
    margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/WorkflowPage/WorkflowPage.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,uBAAuB;CACvB,aAAa;AACd;;AAEA;CACC,WAAW;CACX,YAAY;AACb;;AAEA;IACI,eAAe;IACf,SAAS;IACT,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,cAAc;IACd,WAAW;AACf;;AAEA;CACC,WAAW;IACR,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".main-container {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n\theight: 100vh;\n}\n\n.ui-container {\n\twidth: 100%;\n\theight: 100%;\n}\n\n.logout-container {\n    position: fixed;\n    top: 10px;\n    right: 10px;\n    z-index: 1000;\n}\n\n.logout-container Button {\n    border: none;\n    text-align: center;\n    display: block;\n    width: 100%;\n}\n\n.logout-container div {\n\tcolor: gray;\n    text-align: center;\n    margin-top: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
