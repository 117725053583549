// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.final-data-table {
	height: 20.625rem;
	width: 70%;
	border: 1px solid #ccc;
	overflow-x: auto;
	background: #D2D9FA;
}

.rule-output-final {
	margin-bottom: 1.25rem;
	display: flex;
	align-items: center;
	gap: 0.625rem;
}

.scroll-x {
	overflow-x: auto;
}

.final-flex-container {
	display: flex;
	flex-direction: column;
	gap: 0.625rem;
	padding-top: 0.625rem;
	padding-left: 0.3125rem;
	padding-right: 0.3125rem;
}

.final-flex-row-container {
	display: flex;
	flex-direction: row;
	gap: 0.625rem;
}

.line {
	width: 100%;
	height: 0.0625rem;
	background-color: black;
	margin-top: 0.625rem;
}

.final-flex-header {
	font-weight: bold;
	flex: 1 1;
	width: 3.125rem;
}

.final-flex-item {
	flex: 1 1;
	width: 3.4375rem;
	word-break: break-all;
}

.final-empty-space-header {
	width: 3.125rem;
	color: #D2D9FA;
}

.final-device-name {
	font-weight: bold;
	color: black;
}

.final-flex-row {
	display: flex;
	flex-direction: row;
	gap: 0.625rem;
}`, "",{"version":3,"sources":["webpack://./src/components/FinalDeviceDataTable/FinalDeviceDataTable.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;CACjB,UAAU;CACV,sBAAsB;CACtB,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC,sBAAsB;CACtB,aAAa;CACb,mBAAmB;CACnB,aAAa;AACd;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,aAAa;CACb,qBAAqB;CACrB,uBAAuB;CACvB,wBAAwB;AACzB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,aAAa;AACd;;AAEA;CACC,WAAW;CACX,iBAAiB;CACjB,uBAAuB;CACvB,oBAAoB;AACrB;;AAEA;CACC,iBAAiB;CACjB,SAAO;CACP,eAAe;AAChB;;AAEA;CACC,SAAO;CACP,gBAAgB;CAChB,qBAAqB;AACtB;;AAEA;CACC,eAAe;CACf,cAAc;AACf;;AAEA;CACC,iBAAiB;CACjB,YAAY;AACb;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,aAAa;AACd","sourcesContent":[".final-data-table {\n\theight: 20.625rem;\n\twidth: 70%;\n\tborder: 1px solid #ccc;\n\toverflow-x: auto;\n\tbackground: #D2D9FA;\n}\n\n.rule-output-final {\n\tmargin-bottom: 1.25rem;\n\tdisplay: flex;\n\talign-items: center;\n\tgap: 0.625rem;\n}\n\n.scroll-x {\n\toverflow-x: auto;\n}\n\n.final-flex-container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 0.625rem;\n\tpadding-top: 0.625rem;\n\tpadding-left: 0.3125rem;\n\tpadding-right: 0.3125rem;\n}\n\n.final-flex-row-container {\n\tdisplay: flex;\n\tflex-direction: row;\n\tgap: 0.625rem;\n}\n\n.line {\n\twidth: 100%;\n\theight: 0.0625rem;\n\tbackground-color: black;\n\tmargin-top: 0.625rem;\n}\n\n.final-flex-header {\n\tfont-weight: bold;\n\tflex: 1;\n\twidth: 3.125rem;\n}\n\n.final-flex-item {\n\tflex: 1;\n\twidth: 3.4375rem;\n\tword-break: break-all;\n}\n\n.final-empty-space-header {\n\twidth: 3.125rem;\n\tcolor: #D2D9FA;\n}\n\n.final-device-name {\n\tfont-weight: bold;\n\tcolor: black;\n}\n\n.final-flex-row {\n\tdisplay: flex;\n\tflex-direction: row;\n\tgap: 0.625rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
