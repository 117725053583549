import { COLORS } from '../themes';
import { POSITIONS } from './positions.constants';

const ROUTER_BOX = {
	id: 'Router-Box-Node',
	data: { label: 'Router Box' },
	position: { x: 10, y: 220 },
	style: { width: 200, background: COLORS.bg_color_2 },
	sourcePosition: POSITIONS.RIGHT
};

const IOT_CORE = {
	id: 'Iot-Core-Node',
	data: { label: 'Iot Core' },
	position: { x: 300, y: 300 },
	style: { width: 100, background: COLORS.bg_color_2 },
	sourcePosition: POSITIONS.BOTTOM,
	targetPosition: POSITIONS.LEFT
};

const DECODING_RAW_INFO = {
	id: 'Decoding-Raw-Info-Node',
	data: { label: 'Decoding raw information' },
	position: { x: 295, y: 475 },
	style: { width: 110, background: COLORS.bg_color_1, fontSize: '1rem', fontWeight: 'bold' },
	targetPosition: POSITIONS.TOP,
	sourcePosition: POSITIONS.RIGHT
};

const RAW_DATA_BOX = {
	id: 'Raw-Data-Box-Node',
	data: { label: 'Raw Data Box' },
	position: { x: 455, y: 83.7 },
	style: { width: 580, background: COLORS.bg_color_1 },
	targetPosition: POSITIONS.LEFT,
	sourcePosition: POSITIONS.RIGHT
};

const ELASTIC_CACHE = {
	id: 'Elastic-Cache-Node',
	data: { label: 'Elastic Cache' },
	position: { x: 1085, y: 308.5 },
	style: { width: 100, background: COLORS.bg_color_3 },
	targetPosition: POSITIONS.LEFT,
	sourcePosition: POSITIONS.RIGHT
};

const DHCP_RULE = {
	id: 'DHCP-Rule-Node',
	data: { label: 'DHCP Rule' },
	position: { x: 1250, y: 350 },
	style: { width: 600 },
	targetPosition: POSITIONS.LEFT,
	sourcePosition: POSITIONS.RIGHT
};

const USER_AGENT_RULE = {
	id: 'User-Agent-Rule-Node',
	data: { label: 'User Agent Rule' },
	position: { x: 1250, y: -500 },
	style: { width: 600 },
	targetPosition: POSITIONS.LEFT,
	sourcePosition: POSITIONS.RIGHT
};

const MDNS_RULE = {
	id: 'MDNS-Rule-Node',
	data: { label: 'MDNS Rule' },
	position: { x: 1250, y: -75 },
	style: { width: 600 },
	targetPosition: POSITIONS.LEFT,
	sourcePosition: POSITIONS.RIGHT
};


const WIFI_ASSOCIATION_RULE = {
	id: 'Wifi-Association-Rule-Node',
	data: { label: 'Wifi Association Rule' },
	position: { x: 1250, y: 775 },
	style: { width: 600 },
	targetPosition: POSITIONS.LEFT,
	sourcePosition: POSITIONS.RIGHT
};


const RULE_PROCESSOR = {
	id: 'Rule-Processor-Node',
	data: { label: 'Rule Processor' },
	position: { x: 1900, y: 105 },
	style: { width: 1520, background: COLORS.bg_color_4, border: 'none', overflow: 'hidden', padding: 0 },
	targetPosition: POSITIONS.LEFT,
	sourcePosition: POSITIONS.RIGHT,
	selectable: false,
};

const SEC_API = {
	id: 'Sec-API-Button-Node',
	data: { label: 'Sec API Button' },
	position: { x: 3498, y: 320 },
	style: { width: 100, background: COLORS.bg_color_2, cursor: 'pointer' },
	targetPosition: POSITIONS.LEFT,
	sourcePosition: POSITIONS.RIGHT
};

const CUSTOMER_APP = {
	id: 'Customer-App-Node',
	type: 'output',
	data: { label: 'Customer App' },
	position: { x: 3700, y: 185 },
	style: { width: 100, background: COLORS.bg_color_2 },
	targetPosition: POSITIONS.LEFT,
};

const CUSTOMER_SUPPORT = {
	id: 'Customer-Support-Node',
	type: 'output',
	data: { label: 'Customer Support' },
	position: { x: 3700, y: 430 },
	style: { width: 100, background: COLORS.bg_color_2 },
	targetPosition: POSITIONS.LEFT,
};

const NODES = {
	ROUTER_BOX,
	IOT_CORE,
	DECODING_RAW_INFO,
	RAW_DATA_BOX,
	ELASTIC_CACHE,
	DHCP_RULE,
	USER_AGENT_RULE,
	MDNS_RULE,
	WIFI_ASSOCIATION_RULE,
	RULE_PROCESSOR,
	SEC_API,
	CUSTOMER_APP,
	CUSTOMER_SUPPORT
};

export { NODES };