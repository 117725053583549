import React from 'react';
import PropTypes from 'prop-types';


const DashboardSection = (props) => {
	const { label, imgSrc } = props;
	return (
		<div className="iot-core-container">
			<img src={imgSrc} alt="SecApiIcon" width="64" height="64" />
			<p>{label}</p>
		</div>
	);
};

DashboardSection.propTypes = {
	label: PropTypes.string.isRequired,
	imgSrc: PropTypes.any.isRequired
};

export default DashboardSection;