import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const getMDSNRuleOutput = async (deviceId) => {
	const mdnsURL = `${process.env.REACT_APP_BACKEND_URL}/rules/${deviceId}/mdnsOutput`;
	const fetchMDSNRuleOutput = await axios.get(mdnsURL);
	return fetchMDSNRuleOutput.data;
};

const useFetchMDSNRuleOutput = (deviceId) => {
	return useQuery({
		queryKey: ['getMDSNOutput', deviceId],
		queryFn: () => getMDSNRuleOutput(deviceId),
		retry: false,
		enabled: false
	});
};

export { useFetchMDSNRuleOutput };
