import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import axios from 'axios';

const saveRule = async (selectedRule, selectedRuleContent) => {
	const payload = { selectedRule, selectedRuleContent };
	const saveRuleURL = `${process.env.REACT_APP_BACKEND_URL}/rules/`;
	const response = await axios.post(saveRuleURL, payload);
	return response.data;
};

const getSingleRule = async (selectedRule) => {
	const params = { rule: selectedRule };
	const getRuleURL = `${process.env.REACT_APP_BACKEND_URL}/rules/single`;
	const response = await axios.get(getRuleURL, { params });
	return response.data;
};

const getRulesConfigFiles = async () => {
	const rulesConfigFilesURL = `${process.env.REACT_APP_BACKEND_URL}/rules/`;
	const response = await axios.get(rulesConfigFilesURL);
	return response.data;
};


const useFetchAndUpdateRules = (
	onUpdateRuleSuccess,
	onUpdateRuleFailed,
) => {
	const [selectedRule, setSelectedRule] = useState();
	const [selectedRuleContent, setSelectedRuleContent] = useState();

	const updateRuleMutation = useMutation({
		mutationFn: () => saveRule(selectedRule, selectedRuleContent),
		onSuccess: onUpdateRuleSuccess,
		onError: onUpdateRuleFailed
	});

	const { data: ruleConfigFiles } = useQuery({
		queryKey: [getRulesConfigFiles.name],
		queryFn: () => getRulesConfigFiles(),
	});

	const { data: rawRuleContent, isLoading: isFetchingRuleContent } = useQuery({
		queryKey: [getSingleRule.name, selectedRule],
		queryFn: () => getSingleRule(selectedRule),
		enabled: !!selectedRule,
	});

	useEffect(() => {
		setSelectedRuleContent(rawRuleContent);
	}, [rawRuleContent]);

	return {
		ruleConfigFiles,
		updateRuleMutation,
		rule: {
			selectedRule,
			setSelectedRule,
		},
		ruleContent: {
			isLoading: isFetchingRuleContent,
			setSelectedRuleContent,
			selectedRuleContent,
		}
	};
};

export { useFetchAndUpdateRules };