import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const getRawData = async (deviceId) => {
	const rawDataURL = `${process.env.REACT_APP_BACKEND_URL}/data/${deviceId}/getRawData`;
	const fetchRawData = await axios.get(rawDataURL);
	return fetchRawData.data;
};

const useFetchRawData = (deviceId) => {
	return useQuery({
		queryKey: ['getRawData', deviceId],
		queryFn: () => getRawData(deviceId),
		retry: false,
		enabled: false
	});
};

export { useFetchRawData };
