// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Loader styles */
.loader {
	border: 0.25rem solid rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	border-top: 0.25rem solid #000;
	width: 1.5rem;
	height: 1.5rem;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/* Center the loader inside the container */
.iot-core-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/SecApiButton/index.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;CACC,wCAAwC;CACxC,kBAAkB;CAClB,8BAA8B;CAC9B,aAAa;CACb,cAAc;CACd,kCAAkC;AACnC;;AAEA;CACC;EACC,uBAAuB;CACxB;;CAEA;EACC,yBAAyB;CAC1B;AACD;;AAEA,2CAA2C;AAC3C;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,uBAAuB;AACxB","sourcesContent":["/* Loader styles */\n.loader {\n\tborder: 0.25rem solid rgba(0, 0, 0, 0.1);\n\tborder-radius: 50%;\n\tborder-top: 0.25rem solid #000;\n\twidth: 1.5rem;\n\theight: 1.5rem;\n\tanimation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n\t0% {\n\t\ttransform: rotate(0deg);\n\t}\n\n\t100% {\n\t\ttransform: rotate(360deg);\n\t}\n}\n\n/* Center the loader inside the container */\n.iot-core-container {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
