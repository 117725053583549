import React from 'react';
import PropTypes from 'prop-types';

import './RuleProcessingOutputTable.css';
import { Utils } from '../../utils';
import { INITIAL_STATE } from '../../constants/state.constants';

const RuleProcessingOutputTable = (props) => {
	const { data } = props;
	return (
		<div className="data-table p-2">
			<div className='flex-row-container'>
				<div className="flex-header custom-flex-header" >Device ID</div>
				<div className="flex-header">Type</div>
				<div className="flex-header">Value</div>
				<div className="flex-header">Trust Level</div>
				<div className="flex-header">Time Stamp</div>
			</div>
			<div className='scroll-y table-height'>
				{data.map((rule, index) => (
					<div key={index} className='scroll-y nowheel'>
						<div className="rule-output">
							<h6 className='pt-4 first-rule-output-h2'>{rule.clientMac == INITIAL_STATE.EMPTY_VALUE ? INITIAL_STATE.EMPTY_VALUE : rule.clientMac}</h6>
							<div className="flex-container">
								<div className="line-full" />
								{rule.meta.map((entry, idx) => (
									<div key={idx}>
										<div className="flex-row" >
											<div className="flex-item">{entry['type']}</div>
											<div className="flex-item">{entry['value']}</div>
											<div className="flex-item">{entry['trustLevel']}</div>
											<div className="flex-item">{Utils.convertDateTimeToReadable(entry['timestamp'])}</div>
										</div>
										{idx != rule.meta.length - 1 && <div className='line' />}
									</div>
								))}
							</div>
						</div>
					</div>
				))
				}
			</div>
		</div >
	);
};

export default RuleProcessingOutputTable;

RuleProcessingOutputTable.propTypes = {
	data: PropTypes.array.isRequired,
};