import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const getConflictResolvedData = async (deviceId) => {
	const conflictResolvedDataURL = `${process.env.REACT_APP_BACKEND_URL}/data//${deviceId}/getConflictResolvedRuleOutput`;
	const fetchConflictResolvedData = await axios.get(conflictResolvedDataURL);
	return fetchConflictResolvedData.data;
};

const useFetchConflictResolvedOutput = (deviceId) => {
	return useQuery({
		queryKey: ['getConflictResolvedData', deviceId],
		queryFn: () => getConflictResolvedData(deviceId),
		retry: false,
		enabled: false
	});
};

export { useFetchConflictResolvedOutput };
