import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const getWifiAssocRuleOutput = async (deviceId) => {
	const wifiAssocRuleOutputURL = `${process.env.REACT_APP_BACKEND_URL}/rules/${deviceId}/wifiAssocOutput`;
	const fetchWifiAssocRuleOutput = await axios.get(wifiAssocRuleOutputURL);
	return fetchWifiAssocRuleOutput.data;
};

const useFetchWifiAssocRuleOutput = (deviceId) => {
	return useQuery({
		queryKey: ['getWifiAssocOutput', deviceId],
		queryFn: () => getWifiAssocRuleOutput(deviceId),
		retry: false,
		enabled: false
	});
};

export { useFetchWifiAssocRuleOutput };
