import React from 'react';
import './RouterBox.css';

import RouterIcon from '../../assets/router.png';

const RouterBox = () => {
	return (
		<div className='router-box-container '>
			<div className='top-heading'>
				<p>John&apos;s home</p>
				<p>&lt;John&apos;s-devices&gt;</p>
			</div>
			<ul>
				<li>Samsung phone</li>
				<li>Asus ROG Phone</li>
				<li>iPhone</li>
				<li>LG TV</li>
				<li>TiVo</li>
				<li>HP Desktop PC</li>
				<li>PlayStation 5</li>
			</ul>
			<div className='router-section'>
				<img src={RouterIcon} width='64' height='64' alt='broadband' />
				<p> Airties Home Network</p>
			</div>
		</div>
	);
};

export default RouterBox;
