import React from 'react';
import './IotCoreSection.css';
import IotCoreImg from '../../assets/iot-core.jpeg';
const IotCoreSection = () => {
	return (
		<div className='iot-core-container'>
			<img src={IotCoreImg} width="64" height="64" alt="iot-core" />
			<p>IOT Core</p>
		</div>
	);
};

export default IotCoreSection;