import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

const loginUser = async ({ username, password, deviceId }) => {
	const payload = { username, password, deviceId };
	const userLoginURL = `${process.env.REACT_APP_BACKEND_URL}/auth/login`;
	try {
		const response = await axios.post(userLoginURL, payload);
		return response;
	}
	catch (error) {
		throw new AxiosError(error.response.data);
	}
};

const useUserLogin = (onLoginSuccess, onLoginError) => {
	return useMutation({
		mutationFn: loginUser,
		onSuccess: onLoginSuccess,
		onError: onLoginError
	});
};

export { useUserLogin };