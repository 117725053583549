import React from 'react';
import { LoginPage, NotFoundErrorPage, WorkflowPage } from './pages';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ProtectedRoute } from './components';

const client = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
		},
		useErrorBoundary: true,
	},
});

const router = createBrowserRouter([
	{
		path: '/',
		children: [
			{
				index: true,
				element: <LoginPage />
			},
			{
				path: 'workflow',
				element:
					<ProtectedRoute >
						<WorkflowPage />
					</ProtectedRoute>
			},
			{
				path: '*',
				element: <NotFoundErrorPage />
			}
		]
	}
]);

function App() {
	return (
		<QueryClientProvider client={client}>
			<RouterProvider router={router} />
		</QueryClientProvider>
	);
}

export default App;
