// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
}

.error-page-container h1 {
	font-size: 4rem;
}

.error-page-container h2 {
	font-size: 3rem;
}

.error-page-container p {
	font-size: 1.5rem;
}

.error-page-home-button {
	margin-top: 1rem;
	padding: 0.5rem 1rem;
	background-color: #f1f1f1;
	border: none;
	border-radius: 5px;
	cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/NotFoundErrorPage/NotFoundErrorpage.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,uBAAuB;CACvB,mBAAmB;CACnB,aAAa;CACb,WAAW;AACZ;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,gBAAgB;CAChB,oBAAoB;CACpB,yBAAyB;CACzB,YAAY;CACZ,kBAAkB;CAClB,eAAe;AAChB","sourcesContent":[".error-page-container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\talign-items: center;\n\theight: 100vh;\n\twidth: 100%;\n}\n\n.error-page-container h1 {\n\tfont-size: 4rem;\n}\n\n.error-page-container h2 {\n\tfont-size: 3rem;\n}\n\n.error-page-container p {\n\tfont-size: 1.5rem;\n}\n\n.error-page-home-button {\n\tmargin-top: 1rem;\n\tpadding: 0.5rem 1rem;\n\tbackground-color: #f1f1f1;\n\tborder: none;\n\tborder-radius: 5px;\n\tcursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
