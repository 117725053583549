const HTTP_RULE_TABLE_HEADER = 'HTTP User Agent Rule Outputs';
const DHCP_RULE_TABLE_HEADER = 'DHCP Rule Outputs';
const MDNS_RULE_TABLE_HEADER = 'MDNS Rule Outputs';
const WIFI__RULE_TABLE_HEADER = 'Wi-Fi Association Frame Rule Outputs';
const MERGED_RULE_TABLE_HEADER = 'Merged Rule Outputs';
const CONFLICT_RESOLVED_TABLE_HEADER = 'Conflict Resolved Rule Outputs';
const FINAL_DEVICE_TABLE_HEADER = 'Final Device Data Outputs';


const HEADERS = {
	HTTP_RULE_TABLE_HEADER,
	DHCP_RULE_TABLE_HEADER,
	MDNS_RULE_TABLE_HEADER,
	WIFI__RULE_TABLE_HEADER,
	MERGED_RULE_TABLE_HEADER,
	CONFLICT_RESOLVED_TABLE_HEADER,
	FINAL_DEVICE_TABLE_HEADER
};

export { HEADERS };