import { MarkerType } from 'reactflow';
import { COLORS } from '../themes';
import { NODES } from './nodes.constants';

const EDGES = [
	{
		id: 'e-Router-Box->Iot-Core',
		source: NODES.ROUTER_BOX.id,
		target: NODES.IOT_CORE.id,
		type: 'smoothstep',
		markerEnd: {
			type: MarkerType.ArrowClosed,
			width: 25,
			height: 25,
			color: COLORS.primary_color
		},
		style: { stroke: COLORS.primary_color, strokeWidth: 2 }
	},
	{
		id: 'e-Iot-Core->Decoding-Raw-Info',
		source: NODES.IOT_CORE.id,
		target: NODES.DECODING_RAW_INFO.id,
		type: 'smoothstep',
		markerEnd: {
			type: MarkerType.ArrowClosed,
			width: 25,
			height: 25,
			color: COLORS.primary_color
		},
		style: { stroke: COLORS.primary_color, strokeWidth: 2 }
	},
	{
		id: 'e-Decoding-Raw-Info->Raw-Data-Box',
		source: NODES.DECODING_RAW_INFO.id,
		target: NODES.RAW_DATA_BOX.id,
		type: 'smoothstep',
		markerEnd: {
			type: MarkerType.ArrowClosed,
			width: 25,
			height: 25,
			color: COLORS.primary_color
		},
		style: { stroke: COLORS.primary_color, strokeWidth: 2 }
	},
	{
		id: 'e-Raw-Data-Box->Elastic-Cache',
		source: NODES.RAW_DATA_BOX.id,
		target: NODES.ELASTIC_CACHE.id,
		type: 'smoothstep',
		markerEnd: {
			type: MarkerType.ArrowClosed,
			width: 25,
			height: 25,
			color: COLORS.primary_color
		},
		style: { stroke: COLORS.primary_color, strokeWidth: 2 }
	},
	{
		id: 'e-Elastic-Cache->DHCP-Rule',
		source: NODES.ELASTIC_CACHE.id,
		target: NODES.DHCP_RULE.id,
		type: 'smoothstep',
		markerEnd: {
			type: MarkerType.ArrowClosed,
			width: 25,
			height: 25,
			color: COLORS.primary_color
		},
		style: { stroke: COLORS.primary_color, strokeWidth: 2 }
	},
	{
		id: 'e-Elastic-Cache->MDNS-Rule',
		source: NODES.ELASTIC_CACHE.id,
		target: NODES.MDNS_RULE.id,
		type: 'smoothstep',
		markerEnd: {
			type: MarkerType.ArrowClosed,
			width: 25,
			height: 25,
			color: COLORS.primary_color
		},
		style: { stroke: COLORS.primary_color, strokeWidth: 2 }
	},
	{
		id: 'e-Elastic-Cache->User-Agent-Rule',
		source: NODES.ELASTIC_CACHE.id,
		target: NODES.USER_AGENT_RULE.id,
		type: 'smoothstep',
		markerEnd: {
			type: MarkerType.ArrowClosed,
			width: 25,
			height: 25,
			color: COLORS.primary_color
		},
		style: { stroke: COLORS.primary_color, strokeWidth: 2 }
	},
	{
		id: 'e-Elastic-Cache->Wifi-Association-Rule',
		source: NODES.ELASTIC_CACHE.id,
		target: NODES.WIFI_ASSOCIATION_RULE.id,
		type: 'smoothstep',
		markerEnd: {
			type: MarkerType.ArrowClosed,
			width: 25,
			height: 25,
			color: COLORS.primary_color
		},
		style: { stroke: COLORS.primary_color, strokeWidth: 2 }
	},
	{
		id: 'e-DHCP-Rule->Rule-Processor',
		source: NODES.DHCP_RULE.id,
		target: NODES.RULE_PROCESSOR.id,
		type: 'smoothstep',
		markerEnd: {
			type: MarkerType.ArrowClosed,
			width: 25,
			height: 25,
			color: COLORS.primary_color
		},
		style: { stroke: COLORS.primary_color, strokeWidth: 2 }
	},
	{
		id: 'e-MDNS-Rule->Rule-Processor',
		source: NODES.MDNS_RULE.id,
		target: NODES.RULE_PROCESSOR.id,
		type: 'smoothstep',
		markerEnd: {
			type: MarkerType.ArrowClosed,
			width: 25,
			height: 25,
			color: COLORS.primary_color
		},
		style: { stroke: COLORS.primary_color, strokeWidth: 2 }
	},
	{
		id: 'e-User-Agent-Rule->Rule-Processor',
		source: NODES.USER_AGENT_RULE.id,
		target: NODES.RULE_PROCESSOR.id,
		type: 'smoothstep',
		markerEnd: {
			type: MarkerType.ArrowClosed,
			width: 25,
			height: 25,
			color: COLORS.primary_color
		},
		style: { stroke: COLORS.primary_color, strokeWidth: 2 }
	},
	{
		id: 'e-Wifi-Association-Rule->Rule-Processor',
		source: NODES.WIFI_ASSOCIATION_RULE.id,
		target: NODES.RULE_PROCESSOR.id,
		type: 'smoothstep',
		markerEnd: {
			type: MarkerType.ArrowClosed,
			width: 25,
			height: 25,
			color: COLORS.primary_color
		},
		style: { stroke: COLORS.primary_color, strokeWidth: 2 }
	},
	{
		id: 'e-Rule-Processor->Sec-API-Button',
		source: NODES.RULE_PROCESSOR.id,
		target: NODES.SEC_API.id,
		type: 'smoothstep',
		markerEnd: {
			type: MarkerType.ArrowClosed,
			width: 25,
			height: 25,
			color: COLORS.primary_color
		},
		markerStart: {
			type: MarkerType.ArrowClosed,
			width: 25,
			height: 25,
			color: COLORS.primary_color,
			orient: 'auto-start-reverse'
		},
		style: { stroke: COLORS.primary_color, strokeWidth: 2 }
	},
	{
		id: 'e-Sec-API-Button->Customer-App',
		source: NODES.SEC_API.id,
		target: NODES.CUSTOMER_APP.id,
		type: 'smoothstep',
		markerEnd: {
			type: MarkerType.ArrowClosed,
			width: 25,
			height: 25,
			color: COLORS.primary_color
		},
		markerStart: {
			type: MarkerType.ArrowClosed,
			width: 25,
			height: 25,
			color: COLORS.primary_color,
			orient: 'auto-start-reverse'
		},
		style: { stroke: COLORS.primary_color, strokeWidth: 2 }
	},
	{
		id: 'e-Sec-API-Button->Customer-Support',
		source: NODES.SEC_API.id,
		target: NODES.CUSTOMER_SUPPORT.id,
		type: 'smoothstep',
		markerEnd: {
			type: MarkerType.ArrowClosed,
			width: 25,
			height: 25,
			color: COLORS.primary_color
		},
		markerStart: {
			type: MarkerType.ArrowClosed,
			width: 25,
			height: 25,
			color: COLORS.primary_color,
			orient: 'auto-start-reverse'
		},
		style: { stroke: COLORS.primary_color, strokeWidth: 2 }
	}
];

export { EDGES };