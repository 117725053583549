import { useMutation } from '@tanstack/react-query';
import axios from 'axios';


const applyRules = async ({ username, password }) => {
	const payload = { username, password };
	const updateRulesURL = `${process.env.REACT_APP_BACKEND_URL}/rules/updateRules`;
	const response = await axios.post(updateRulesURL, payload);
	console.log('response', response);
	return response.data;
};

const useApplyRules = (onUpdateRulesSuccess, onUpdateRulesError) => {
	return useMutation({
		mutationFn: applyRules,
		onSuccess: onUpdateRulesSuccess,
		onError: onUpdateRulesError,
	});
};

export { useApplyRules };