import React from 'react';
import PropTypes from 'prop-types';
import SecApiIcon from '../../assets/api.png';
import { useState } from 'react';
import './index.css';

const SecApiButton = (props) => {
	const [loading, setLoading] = useState(false);

	const handleClick = async () => {
		setLoading(true);
		try {
			props.fetchFinalDeviceData({
				username: sessionStorage.getItem('username'),
				password: sessionStorage.getItem('password'),
				deviceId: sessionStorage.getItem('deviceId')
			});
		} catch (error) {
			console.error('Error fetching data', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className='iot-core-container' onClick={handleClick}>
			{loading ? (
				<div className='loader'></div>
			) : (
				<>
					<img src={SecApiIcon} alt='SecApiIcon' width='64' height='64' />
					<p>Sec API</p>
				</>
			)}
		</div>
	);
};

export default SecApiButton;

SecApiButton.propTypes = {
	fetchFinalDeviceData: PropTypes.func.isRequired,
};
