import { useQuery } from '@tanstack/react-query';
import axios from 'axios';


const getDHCPRuleOutput = async ({ queryKey }) => {
	const deviceId = queryKey[1];
	const dhcpURL = `${process.env.REACT_APP_BACKEND_URL}/rules/${deviceId}/dhcpOutput`;
	const response = await axios.get(dhcpURL);
	return response.data;
};

const useFetchDHCPRuleOutput = (deviceId) => {
	return useQuery({
		queryKey: ['getDHCPOutput', deviceId],
		queryFn: getDHCPRuleOutput,
		retry: false,
		enabled: false
	});
};

export { useFetchDHCPRuleOutput };
