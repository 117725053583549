import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const getMergedRuleOutput = async (deviceId) => {
	const mergedRuleOutputURL = `${process.env.REACT_APP_BACKEND_URL}/data/${deviceId}/getMergedRuleOutput`;
	const fetchMergedRuleOutput = await axios.get(mergedRuleOutputURL);
	return fetchMergedRuleOutput.data;
};

const useFetchMergedRuleOutput = (deviceId) => {
	return useQuery({
		queryKey: ['getMergedRuleOutput', deviceId],
		queryFn: () => getMergedRuleOutput(deviceId),
		retry: false,
		enabled: false
	});
};

export { useFetchMergedRuleOutput };
