import React from 'react';
import './NotFoundErrorpage.css';

const NotFoundErrorPage = () => {
	return (
		<div className="error-page-container">
			<h1>404</h1>
			<h2>Page Not Found</h2>
			<p>
				Sorry, but the page you are looking does not exist. Please go back to the workflow.
			</p>
			<button className='error-page-home-button' onClick={() => window.location.href = '/workflow'}>Go to Workflow</button>
		</div>
	);
};

export default NotFoundErrorPage;