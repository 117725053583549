import React from 'react';
import ElasticCacheImg from '../../assets/elastic-cache.jpeg';

const ElasticCacheSection = () => {
	return (
		<div className='iot-core-container'>
			<img src={ElasticCacheImg} width="64" height="64" alt="iot-core" />
			<p>Redis Cache</p>
		</div>
	);
};

export default ElasticCacheSection;
