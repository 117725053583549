import React, { useState, useCallback, useEffect } from 'react';
import ReactFlow, { applyNodeChanges, Controls } from 'reactflow';
import { ToastContainer } from 'react-toastify';

import {
	DashboardSection,
	ElasticCacheSection,
	IotCoreSection,
	RawDataBox,
	RouterBox,
	RuleProcessor,
	SecApiButton,
	RuleOutputTable
} from '../../components';

import './WorkflowPage.css';
import 'reactflow/dist/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';

import DashboardSectionImg from '../../assets/smartphone.png';
import CustServiceImg from '../../assets/customer-service.png';
import { INITIAL_STATE } from '../../constants/state.constants';
import { EDGES } from '../../constants/edges.constants';
import { HEADERS } from '../../constants/headers.constants';
import { useFetchRawData } from '../../httpRequestHooks/useFetchRawData';
import { useFetchUserAgentRuleOutput } from '../../httpRequestHooks/useFetchUserAgentRuleOutput';
import { useFetchMDSNRuleOutput } from '../../httpRequestHooks/useFetchMDSNRuleOutput';
import { useFetchDHCPRuleOutput } from '../../httpRequestHooks/useFetchDHCPRuleOutput';
import { useFetchWifiAssocRuleOutput } from '../../httpRequestHooks/useFetchWifiAssocRuleOutput';
import { useFetchMergedRuleOutput } from '../../httpRequestHooks/useFetchMergedRuleOutput';
import { useFetchConflictResolvedOutput } from '../../httpRequestHooks/useFetchConflictResolvedOutput';
import { useFetchFinalDeviceOutput } from '../../httpRequestHooks/useFetchFinalDeviceOutput';
import { NODES } from '../../constants/nodes.constants';
import { usePipeOutDeviceOutput } from '../../httpRequestHooks/usePipeOutDeviceOutput';

const WorkflowPage = () => {

	const deviceId = sessionStorage.getItem('deviceId');
	const { data: rawData = {}, refetch: fetchRawData } = useFetchRawData(deviceId);
	const { data: userAgentRuleData = INITIAL_STATE.PACKET_RULE_OUTPUT, refetch: fetchUserAgentRuleData } = useFetchUserAgentRuleOutput(deviceId);
	const { data: mdnsRuleData = INITIAL_STATE.PACKET_RULE_OUTPUT, refetch: fetchMDSNRuleData } = useFetchMDSNRuleOutput(deviceId);
	const { data: dhcpRuleData = INITIAL_STATE.PACKET_RULE_OUTPUT, refetch: fetchDHCPRuleData } = useFetchDHCPRuleOutput(deviceId);
	const { data: wifiAssocRuleData = INITIAL_STATE.PACKET_RULE_OUTPUT, refetch: fetchWifiAssocRuleData } = useFetchWifiAssocRuleOutput(deviceId);
	const { data: mergedRuleData = INITIAL_STATE.RULE_PROCESSOR_OUTPUT, refetch: fetchMergedRuleData } = useFetchMergedRuleOutput(deviceId);
	const { data: conflictResolvedData = INITIAL_STATE.RULE_PROCESSOR_OUTPUT, refetch: fetchConflictResolvedData } = useFetchConflictResolvedOutput(deviceId);

	const handleSuccess = () => {
		fetchData();
	}

	const { data: finalDeviceData = INITIAL_STATE.FINAL_DEVICE_DATA_OUTPUT, mutate: fetchFinalDeviceData } = useFetchFinalDeviceOutput();
	const { mutate: fetchPipeOutData } = usePipeOutDeviceOutput(handleSuccess);

	const fetchData = async () => {
		try {
			await fetchRawData();
			await fetchUserAgentRuleData();
			await fetchMDSNRuleData();
			await fetchDHCPRuleData();
			await fetchWifiAssocRuleData();
			await fetchMergedRuleData();
			await fetchConflictResolvedData();
			fetchFinalDeviceData({
				username: sessionStorage.getItem('username'),
				password: sessionStorage.getItem('password'),
				deviceId: sessionStorage.getItem('deviceId')
			});

		} catch (error) {
			console.log('Error fetching data', error);
		}
	};

	const initialNodes = [
		{
			...NODES.ROUTER_BOX,
			data: { label: <RouterBox /> }
		},
		{
			...NODES.IOT_CORE,
			data: { label: <IotCoreSection /> },
		},
		{
			...NODES.DECODING_RAW_INFO,
		},
		{
			...NODES.RAW_DATA_BOX,
			data: { label: <RawDataBox rawData={JSON.stringify(rawData)} /> },
		},
		{
			...NODES.ELASTIC_CACHE,
			data: { label: <ElasticCacheSection /> },
		},
		{
			...NODES.DHCP_RULE,
			data: { label: <RuleOutputTable header={HEADERS.DHCP_RULE_TABLE_HEADER} data={dhcpRuleData} /> },
		},
		{
			...NODES.USER_AGENT_RULE,
			data: { label: <RuleOutputTable header={HEADERS.HTTP_RULE_TABLE_HEADER} data={userAgentRuleData} /> },

		},
		{
			...NODES.MDNS_RULE,
			data: { label: <RuleOutputTable header={HEADERS.MDNS_RULE_TABLE_HEADER} data={mdnsRuleData} /> },
		},
		{
			...NODES.WIFI_ASSOCIATION_RULE,
			data: { label: <RuleOutputTable header={HEADERS.WIFI__RULE_TABLE_HEADER} data={wifiAssocRuleData} /> },
		},
		{
			...NODES.RULE_PROCESSOR,
			data: { label: <RuleProcessor mergedRuleData={mergedRuleData} conflictResolvedData={conflictResolvedData} finalDeviceData={finalDeviceData} /> },
		},
		{
			...NODES.SEC_API,
			data: { label: <SecApiButton fetchFinalDeviceData={fetchPipeOutData}/> },
		},
		{
			...NODES.CUSTOMER_APP,
			data: { label: <DashboardSection label='Customer App' imgSrc={DashboardSectionImg} /> },
		},
		{
			...NODES.CUSTOMER_SUPPORT,
			data: { label: <DashboardSection label='Customer Support (CareView)' imgSrc={CustServiceImg} /> },
		}
	];

	const [nodes, setNodes] = useState(initialNodes);
	const [edges] = useState(EDGES);

	const onNodesChange = useCallback(
		(changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
		[]
	);

	useEffect(() => {
		setNodes((nds) =>
			nds.map((node) => {
				if (node.id === NODES.RAW_DATA_BOX.id) {
					return {
						...node,
						data: { ...node.data, label: <RawDataBox rawData={JSON.stringify(rawData)} /> }
					};
				}
				if (node.id === NODES.DHCP_RULE.id) {
					return {
						...node,
						data: { ...node.data, label: <RuleOutputTable header={HEADERS.DHCP_RULE_TABLE_HEADER} data={dhcpRuleData} /> }
					};
				}
				if (node.id === NODES.MDNS_RULE.id) {
					return {
						...node,
						data: { ...node.data, label: <RuleOutputTable header={HEADERS.MDNS_RULE_TABLE_HEADER} data={mdnsRuleData} /> }
					};
				}
				if (node.id === NODES.USER_AGENT_RULE.id) {
					return {
						...node,
						data: { ...node.data, label: <RuleOutputTable header={HEADERS.HTTP_RULE_TABLE_HEADER} data={userAgentRuleData} /> }
					};
				}
				if (node.id === NODES.WIFI_ASSOCIATION_RULE.id) {
					return {
						...node,
						data: { ...node.data, label: <RuleOutputTable header={HEADERS.WIFI__RULE_TABLE_HEADER} data={wifiAssocRuleData} /> }
					};
				}
				if (node.id === NODES.RULE_PROCESSOR.id) {
					return {
						...node,
						data: { ...node.data, label: <RuleProcessor mergedRuleData={mergedRuleData} conflictResolvedData={conflictResolvedData} finalDeviceData={finalDeviceData} /> }
					};
				}
				return node;
			})
		);
	}, [rawData, mergedRuleData, conflictResolvedData, finalDeviceData, dhcpRuleData, mdnsRuleData, userAgentRuleData]);

	const translateExtent = [
		[-50, -530],         // Minimum x and y coordinates
		[4000, 1200]      // Maximum x and y coordinates
	];

	const handleLogout = () => {
		// Remove specific items from sessionStorage
		sessionStorage.removeItem('deviceId');
		sessionStorage.removeItem('password');
		sessionStorage.removeItem('username');
		window.location.reload();
    };

	return (
		<div className='main-container p-4'>
			<ToastContainer position='top-center' />
			<div className='ui-container'>
				<ReactFlow
					nodes={nodes}
					edges={edges}
					onNodesChange={onNodesChange}
					zoomOnScroll={false}
					zoomOnPinch={false}
					draggable={false}
					zoomOnDoubleClick={false}
					panOnDrag={true}
					panOnScroll={true}
					translateExtent={translateExtent}
					fitView
					fitViewOptions={{
						nodes: nodes.slice(0, 10)
					}}
				>
					<Controls />
				</ReactFlow>
			</div>
			<div className="logout-container">
				<Button className='p-2' onClick={handleLogout}>Login again</Button>
				<div>To Switch Device ID</div>
			</div>

		</div>
	);
};

export default WorkflowPage;
