const EMPTY_VALUE = '-';

const RULE_PROCESSOR_OUTPUT = [
	{
		clientMac: EMPTY_VALUE,
		meta: [
			{
				type: EMPTY_VALUE,
				value: EMPTY_VALUE,
				trustLevel: EMPTY_VALUE,
				timestamp: EMPTY_VALUE
			}
		]
	}
];

const PACKET_RULE_OUTPUT = {
	[EMPTY_VALUE]: [
		{
			type: EMPTY_VALUE,
			value: EMPTY_VALUE,
			trustLevel: EMPTY_VALUE,
			timestamp: EMPTY_VALUE
		}
	]
};

const FINAL_DEVICE_DATA_OUTPUT = [
	{
		id: EMPTY_VALUE,
		modelAccuracyLevel: EMPTY_VALUE,
		operatingSystem: EMPTY_VALUE,
		osVersion: EMPTY_VALUE,
		gatewayMac: EMPTY_VALUE,
		model: EMPTY_VALUE,
		modelVersion: EMPTY_VALUE,
		brand: EMPTY_VALUE,
		type: EMPTY_VALUE,
		mac: EMPTY_VALUE,
		deviceName: EMPTY_VALUE,
		activeMac: EMPTY_VALUE,
		macs: [EMPTY_VALUE]
	}
];

const INITIAL_STATE = {
	RULE_PROCESSOR_OUTPUT,
	PACKET_RULE_OUTPUT,
	FINAL_DEVICE_DATA_OUTPUT,
	EMPTY_VALUE
};

export { INITIAL_STATE };