const LEFT = 'left';
const RIGHT = 'right';
const TOP = 'top';
const BOTTOM = 'bottom';

const POSITIONS = {
	LEFT,
	RIGHT,
	TOP,
	BOTTOM
};

export { POSITIONS };