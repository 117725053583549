import React from 'react';
import { useUserLogin } from '../../httpRequestHooks/useLogin';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ children }) => {

	const [isAuthenticated, setIsAuthenticated] = React.useState(false);
	const navigate = useNavigate();

	const onLoginSuccess = () => {
		setIsAuthenticated(true);
	};

	const onLoginError = () => {
		navigate('/');
	};

	const { mutate: authenticateUser } = useUserLogin(onLoginSuccess, onLoginError);

	React.useEffect(() => {
		authenticateUser({
			'username': sessionStorage.getItem('username'),
			'password': sessionStorage.getItem('password'),
			'deviceId': sessionStorage.getItem('deviceId')
		});
	}, []);


	return (
		<div>
			{isAuthenticated && children}
			{!isAuthenticated && <div>Loading...</div>}
		</div>
	);

};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
	children: PropTypes.object,
};