// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rdb-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 98%;
}

.rdb-heading-text {
	text-align: left;
	color: #3C2EFF;
	font-size: 1.5rem;
	font-weight: bolder;
}

.rdb-textarea {
	height: 31.25rem;
	width: 100%;
	border: 2px solid #000;
	font-size: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/components/RawDataBox/RawDataBox.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,uBAAuB;CACvB,UAAU;AACX;;AAEA;CACC,gBAAgB;CAChB,cAAc;CACd,iBAAiB;CACjB,mBAAmB;AACpB;;AAEA;CACC,gBAAgB;CAChB,WAAW;CACX,sBAAsB;CACtB,iBAAiB;AAClB","sourcesContent":[".rdb-container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\twidth: 98%;\n}\n\n.rdb-heading-text {\n\ttext-align: left;\n\tcolor: #3C2EFF;\n\tfont-size: 1.5rem;\n\tfont-weight: bolder;\n}\n\n.rdb-textarea {\n\theight: 31.25rem;\n\twidth: 100%;\n\tborder: 2px solid #000;\n\tfont-size: 0.8rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
