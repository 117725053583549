import React, { useState, useEffect } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './AddRuleModal.css';

const validateJson = (data) => {
	try {
		if (typeof data === 'object') return data;
		return JSON.parse(data);
	} catch (error) {
		return;

	}
};
const renderRule = (onEdit, rule) => {
	return (
		<textarea className='arm-textarea' defaultValue={JSON.stringify(rule, null, 2)} onChange={(e) => { e.target.value !== '' && onEdit(e.target.value); }} />
	);

};

export const AddRuleModal = ({ show, rule, handleClose, onSubmit, onRuleChange, title, isLoading }) => {

	const [isValid, setValid] = useState(!!validateJson(rule));
	useEffect(() => {
		setValid(!!validateJson(rule));
	}, [setValid, rule]);

	const onEdit = (newConf) => {
		const newRule = validateJson(newConf);
		if (newRule) {
			onRuleChange(newRule);
			setValid(true);
		}
		else {
			setValid(false);
		}
	};

	return (
		<div
			className="modal show"
			style={{ display: 'block', position: 'initial' }}
		>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{(!isLoading && !isValid) && <Alert variant='danger'>
						Invalid JSON format
					</Alert>}

					{isLoading && <Alert variant='info'>
						Loading Rule Content
					</Alert>}
					{renderRule(onEdit, rule)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button disabled={!isValid} variant="primary" onClick={async () => { await onSubmit(); handleClose(); }}>
						Submit
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

AddRuleModal.propTypes = {
	show: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	rule: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object
	]),
	isLoading: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func,
	onRuleChange: PropTypes.func,
	title: PropTypes.string
};