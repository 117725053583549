import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const fetchFinalDeviceOutput = async ({ username, password, deviceId }) => {
	const payload = { username, password, deviceId };
	const finalDeviceOutputURL = `${process.env.REACT_APP_BACKEND_URL}/data/getFinalDeviceOutput`;
	const fetchFinalDeviceOutput = await axios.post(finalDeviceOutputURL, payload);
	return fetchFinalDeviceOutput.data;

};

const useFetchFinalDeviceOutput = (onSuccess) => {
	return useMutation({
		mutationFn: fetchFinalDeviceOutput,
		onSuccess
	});
};

export { useFetchFinalDeviceOutput };