import { INITIAL_STATE } from '../constants/state.constants';

const beautifyJson = (json) => {
	return JSON.stringify(JSON.parse(json), null, 2);
};

const setToDefault = (value) => {
	return value === INITIAL_STATE.EMPTY_VALUE ? INITIAL_STATE.EMPTY_VALUE : value;
};

const convertDateTimeToReadable = (dateTime) => {
	if (dateTime == INITIAL_STATE.EMPTY_VALUE) return INITIAL_STATE.EMPTY_VALUE;
	const date = new Date(dateTime);
	return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};



const Utils = {
	beautifyJson,
	setToDefault,
	convertDateTimeToReadable
};

export { Utils };