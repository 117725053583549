import React from 'react';
import PropTypes from 'prop-types';

import './FinalDeviceDataTable.css';
import { Utils } from '../../utils';
import { INITIAL_STATE } from '../../constants/state.constants';

const FinalDeviceDataTable = (props) => {
	const { data } = props;
	return (
		<div className="final-data-table scroll-x nowheel">
			<div className="rule-output-final ">
				<div className="final-flex-container">
					<div className='final-flex-row-container'>
						<div className="final-empty-space-header">Device Mac</div>
						<div className="final-flex-header">Device Mac</div>
						<div className="final-flex-header">Device Name</div>
						<div className="final-flex-header">OS</div>
						<div className="final-flex-header">OS Version</div>
						<div className="final-flex-header">Model</div>
						<div className="final-flex-header">Model Version</div>
						<div className="final-flex-header">Brand</div>
						<div className="final-flex-header">Device Type</div>
						<div className="final-flex-header">Total Confidence Score</div>
					</div>
					<div className="line" />
					{
						data.map((device, idx) => (
							<div key={idx}>
								<div className="final-flex-row" >
									<div className="final-flex-item final-empty-space-header final-device-name"> {device.mac == INITIAL_STATE.EMPTY_VALUE ? INITIAL_STATE.EMPTY_VALUE : `Device ${idx + 1}`}</div>
									<div className="final-flex-item">{Utils.setToDefault(device['mac'])}</div>
									<div className="final-flex-item">{Utils.setToDefault(device['deviceName'])}</div>
									<div className="final-flex-item">{Utils.setToDefault(device['operatingSystem'])}</div>
									<div className="final-flex-item">{Utils.setToDefault(device['osVersion'])}</div>
									<div className="final-flex-item">{Utils.setToDefault(device['model'])}</div>
									<div className="final-flex-item">{Utils.setToDefault(device['modelVersion'])}</div>
									<div className="final-flex-item">{Utils.setToDefault(device['brand'])}</div>
									<div className="final-flex-item">{Utils.setToDefault(device['type'])}</div>
									<div className="final-flex-item">{Utils.setToDefault(device['modelAccuracyLevel'])}</div>
								</div>
								{idx != data.length - 1 && <div className='line' />}
							</div>

						))
					}
				</div>
			</div>
		</div>
	);
};

export default FinalDeviceDataTable;

FinalDeviceDataTable.propTypes = {
	data: PropTypes.array.isRequired,
};
