import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const getUserAgentRuleOutput = async (deviceId) => {
	const userAgentURL = `${process.env.REACT_APP_BACKEND_URL}/rules/${deviceId}/userAgentOutput`;
	const fetchUserAgentRuleOutput = await axios.get(userAgentURL);
	return fetchUserAgentRuleOutput.data;
};

const useFetchUserAgentRuleOutput = (deviceId) => {
	return useQuery({
		queryKey: ['getUserAgentOutput', deviceId],
		queryFn: () => getUserAgentRuleOutput(deviceId),
		retry: false,
		enabled: false
	});
};

export { useFetchUserAgentRuleOutput };
