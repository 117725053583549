import React from 'react';
import PropTypes from 'prop-types';

import './RuleOutputTable.css';
import { Utils } from '../../utils';

const RuleOutputTable = (props) => {
	const { header, data } = props;
	return (
		<>
			<h5 className="rp-content-h2">{header}</h5>

			<div className="data-table p-2">
				<div className='flex-row-container'>
					<div className="flex-header custom-flex-header">Device ID</div>
					<div className="flex-header">Type</div>
					<div className="flex-header">Value</div>
					<div className="flex-header">Trust Level</div>
					<div className="flex-header">Time Stamp</div>
				</div>
				<div className='scroll-y table-height' >
					{data && Object.keys(data).map((deviceId, keyIdx) => {
						const rows = data[deviceId];
						return (
							<div key={keyIdx} className='scroll-y nowheel'>
								<div className="rule-output">
									<h6 className='pt-4 first-rule-output-h2'>{deviceId}</h6>
									<div className="flex-container">
										<div className="line-full" />
										{rows.map((entry, idx) => (
											<div key={idx}>
												<div className="flex-row" >
													<div className="flex-item">{entry['type']}</div>
													<div className="flex-item">{entry['value']}</div>
													<div className="flex-item">{entry['trustLevel']}</div>
													<div className="flex-item">{Utils.convertDateTimeToReadable(entry['timestamp'])}</div>
												</div>
												{idx != rows.length - 1 && <div className='line' />}
											</div>
										))}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default RuleOutputTable;

RuleOutputTable.propTypes = {
	header: PropTypes.string,
	data: PropTypes.object,
};
