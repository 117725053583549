// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iot-core-container {
	font-size: 1rem;
	font-weight: bold;
}

.iot-core-container>p {
	margin-bottom: -0.3125rem;
	margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/IotCoreSection/IotCoreSection.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,iBAAiB;AAClB;;AAEA;CACC,yBAAyB;CACzB,aAAa;AACd","sourcesContent":[".iot-core-container {\n\tfont-size: 1rem;\n\tfont-weight: bold;\n}\n\n.iot-core-container>p {\n\tmargin-bottom: -0.3125rem;\n\tmargin-top: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
