// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddNewRule_buttons-container__SduXK {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}

.AddNewRule_button__HwAx- {
	white-space: nowrap;
}

.AddNewRule_update-rule-button__i57zt {
	background: #3C2EFF;
}

.AddNewRule_button-icon__tNYzx {
	margin-right: 0.25rem;
}`, "",{"version":3,"sources":["webpack://./src/components/AddNewRule/index.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,SAAS;AACV;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,qBAAqB;AACtB","sourcesContent":[".buttons-container {\n\tdisplay: flex;\n\tflex-direction: row;\n\tgap: 1rem;\n}\n\n.button {\n\twhite-space: nowrap;\n}\n\n.update-rule-button {\n\tbackground: #3C2EFF;\n}\n\n.button-icon {\n\tmargin-right: 0.25rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons-container": `AddNewRule_buttons-container__SduXK`,
	"button": `AddNewRule_button__HwAx-`,
	"update-rule-button": `AddNewRule_update-rule-button__i57zt`,
	"button-icon": `AddNewRule_button-icon__tNYzx`
};
export default ___CSS_LOADER_EXPORT___;
