import React from 'react';
import PropTypes from 'prop-types';
import './RuleProcessor.css';

import RuleProcessorIcon from '../../assets/rule-processing.png';
import 'react-toastify/dist/ReactToastify.css';
import RuleProcessingOutputTable from '../RuleProcessingOutputTable';
import FinalDeviceDataTable from '../FinalDeviceDataTable';

import AddNewRule from '../AddNewRule';
import { HEADERS } from '../../constants/headers.constants';

const RuleProcessor = (props) => {
	const { mergedRuleData, finalDeviceData, conflictResolvedData } = props;
	return (
		<>
			<div className='rp-header'>
				<AddNewRule />
			</div>
			<div className='rp-container'>
				<div className='rp-header'>
					<img src={RuleProcessorIcon} width='64' height='64' alt='Rule Processor' className='icon' />
					<p className='rp-header-label'> Rule Processing</p>
				</div>

				<div className='rp-content'>
					<div className='rp-content-col'>
						<h5 className='rp-content-h2'>{HEADERS.MERGED_RULE_TABLE_HEADER}</h5>
						<RuleProcessingOutputTable data={mergedRuleData} />
					</div>
					<div className='rp-content-col'>
						<h5 className='rp-content-h2'>{HEADERS.CONFLICT_RESOLVED_TABLE_HEADER}</h5>
						<RuleProcessingOutputTable data={conflictResolvedData} />
					</div>
					<div className='rp-content-col'>
						<h5 className='rp-content-h2-final'>{HEADERS.FINAL_DEVICE_TABLE_HEADER}</h5>
						<FinalDeviceDataTable data={finalDeviceData} />
					</div>
				</div>
			</div>
		</>
	);
};
export default RuleProcessor;

RuleProcessor.propTypes = {
	mergedRuleData: PropTypes.array.isRequired,
	finalDeviceData: PropTypes.array.isRequired,
	conflictResolvedData: PropTypes.array.isRequired,
};
